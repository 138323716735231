import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  FMPayGroupAccountBookModel,
  FMPayGroupModel,
  FMPayPagination,
  FMPayPaginationTimeRangeQuery,
  FMPayPaginationQuery,
  FMPayResponsePagination,
  FMPayGroupAccountBookAggregationDTO,
  FMPaySettlementMethod
} from "fp/store/type";
import { Services } from "fp/store/Services";

export const groupAccountBookItemGroups = createAsyncThunk<FMPayPagination<FMPayGroupModel>, void>(
  "group.account.book/items.groups",
  async () => {
    return await Services.FMPayGroup.items();
  }
);

export const groupAccountBookPaginationByGroupId = createAsyncThunk<
  FMPayResponsePagination<FMPayGroupAccountBookModel>,
  FMPayPaginationTimeRangeQuery<{ groupId: string }>
>("group.account.book/pagination.by.group.id", async (p) => {
  return await Services.FMPayGroupAccountBookModel.paginationByGroup(p);
});

export const groupAccountBookCreate = createAsyncThunk<
  FMPayGroupAccountBookModel,
  {
    groupId: string;
    amount: string;
    settlement: FMPaySettlementMethod;
    normalBankId: string;
  }
>("group.account.book/create", async (p) => {
  const resp = await Services.FMPayGroupAccountBookModel.create({
    groupId: p.groupId,
    amount: p.amount,
    settlement: p.settlement,
    normalBankId: p.normalBankId
  });
  return resp.data;
});

export const groupAccountBookPaginationAggregation = createAsyncThunk<
  FMPayResponsePagination<FMPayGroupAccountBookAggregationDTO>,
  FMPayPaginationQuery<{ workSchedulingOperationId: string }>
>("group.account.book/pagination.aggregation", async (p) => {
  const model = await Services.FMPayGroupAccountBookModel.aggregation(p);
  return model;
});

export interface GroupAccountBookState {
  groups: FMPayGroupModel[];
  model: FMPayGroupAccountBookModel | null;
  accountBooks: FMPayResponsePagination<FMPayGroupAccountBookAggregationDTO>;
  accounts: FMPayPagination<FMPayGroupAccountBookModel>;
  loading: {
    accounts: boolean;
    groups: boolean;
    accountBooks: boolean;
    create: boolean;
  };
}

const InitialState: GroupAccountBookState = {
  groups: [],
  model: null,
  accountBooks: {
    success: true,
    message: "",
    status: "",
    data: {
      current: 1,
      size: 20,
      total: 0,
      pages: 0,
      latest: false,
      data: []
    }
  },
  accounts: {
    current: 1,
    size: 10,
    total: 0,
    pages: 0,
    latest: false,
    data: []
  },

  loading: {
    accounts: false,
    groups: false,
    accountBooks: false,
    create: false
  }
};

export const groupAccountBookSlice = createSlice({
  name: "groupAccountBook",
  initialState: { ...InitialState },
  reducers: {
    setPage(state, action: PayloadAction<{ current: number; size: number }>) {
      state.accountBooks.data.current = action.payload.current;
      state.accountBooks.data.size = action.payload.size;
    },
    resetAccounts(state) {
      state.accounts.current = 1;
      state.accounts.size = 10;
      state.accounts.total = 0;
      state.accounts.pages = 0;
      state.accounts.latest = false;
      state.accounts.data = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(groupAccountBookCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(groupAccountBookCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(groupAccountBookCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });

    builder.addCase(groupAccountBookPaginationAggregation.pending, (state) => {
      state.loading.accountBooks = true;
    });
    builder.addCase(groupAccountBookPaginationAggregation.rejected, (state) => {
      state.loading.accountBooks = false;
    });
    builder.addCase(groupAccountBookPaginationAggregation.fulfilled, (state, action) => {
      state.loading.accountBooks = false;
      state.accountBooks = action.payload;
    });

    builder.addCase(groupAccountBookItemGroups.pending, (state) => {
      state.loading.groups = true;
    });
    builder.addCase(groupAccountBookItemGroups.rejected, (state) => {
      state.loading.groups = false;
    });
    builder.addCase(groupAccountBookItemGroups.fulfilled, (state, action) => {
      state.loading.groups = false;
      state.groups = action.payload.data;
    });

    builder.addCase(groupAccountBookPaginationByGroupId.pending, (state) => {
      state.loading.accounts = true;
    });
    builder.addCase(groupAccountBookPaginationByGroupId.rejected, (state) => {
      state.loading.accounts = false;
    });
    builder.addCase(groupAccountBookPaginationByGroupId.fulfilled, (state, action) => {
      state.loading.accounts = false;
      state.accounts = action.payload.data;
    });
  }
});

export const { setPage, resetAccounts } = groupAccountBookSlice.actions;
export default groupAccountBookSlice.reducer;
