import {
  FMPaymentInfo,
  FMPayPaginationQuery,
  FMPayResponse,
  FMPayResponsePagination
} from "fp/store/type";
import { BuyBtcPaymentNotifyModel, PaginationQuery } from "fp/modules/buybtcpay/type";
import { HttpClient } from "fp/store/HttpClient";

export class Services {
  public static async active(id: string): Promise<FMPayResponse<FMPaymentInfo>> {
    return await HttpClient.Post(`/v1/buybtc/active/${id}`);
  }

  public static async disable(id: string): Promise<FMPayResponse<FMPaymentInfo>> {
    return await HttpClient.Post(`/v1/buybtc/disable`, { id });
  }

  public static async payments(): Promise<FMPayResponse<FMPaymentInfo[]>> {
    return await HttpClient.Get(`/v1/buybtc/payments`);
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<BuyBtcPaymentNotifyModel>> {
    return await HttpClient.Post(`/v1/buybtc/notify/pagination`, query);
  }

  public static async test(): Promise<{ code: string; msg: string; data: any }> {
    const orderId = "NGN_TEST_" + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    return await HttpClient.Post(`/v1/buybtc/test`, {
      orderId: orderId,
      amount: 100,
      bankAccount: "2003559017",
      bankCode: "50211",
      remark: orderId
    });
  }
}
