import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import {
  CreateDTO,
  FMPayWechatGroupAccountBookModel,
  PaginationQuery
} from "fp/modules/wechat-group-account-book/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(
    id: string
  ): Promise<FMPayResponse<FMPayWechatGroupAccountBookModel>> {
    return await HttpClient.Get(`/v1/wechat/group/account/book/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/wechat/group/account/book/deleted`, { ids });
  }

  public static async update(
    model: FMPayWechatGroupAccountBookModel
  ): Promise<FMPayResponse<FMPayWechatGroupAccountBookModel>> {
    return await HttpClient.Post(`/v1/wechat/group/account/book/update`, model);
  }

  public static async create(
    dto: CreateDTO
  ): Promise<FMPayResponse<FMPayWechatGroupAccountBookModel>> {
    return await HttpClient.Post(`/v1/wechat/group/account/book/add/by/group`, dto);
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayWechatGroupAccountBookModel>> {
    return await HttpClient.Post(`/v1/wechat/group/account/book/pagination`, query);
  }
}
