import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import {
  CreateDTO,
  FMPayBuyBtcAccountModel,
  PaginationQuery
} from "fp/modules/buybtc-account/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(id: string): Promise<FMPayResponse<FMPayBuyBtcAccountModel>> {
    return await HttpClient.Get(`/v1/buybtc/account/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/buybtc/account/deleted`, { ids });
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayBuyBtcAccountModel>> {
    return await HttpClient.Post(`/v1/buybtc/account/pagination`, query);
  }
  public static async update(
    model: FMPayBuyBtcAccountModel
  ): Promise<FMPayResponse<FMPayBuyBtcAccountModel>> {
    return await HttpClient.Post(`/v1/buybtc/account/update`, model);
  }

  public static async create(dto: CreateDTO): Promise<FMPayResponse<FMPayBuyBtcAccountModel>> {
    return await HttpClient.Post(`/v1/buybtc/account/add`, dto);
  }
}
