import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Service } from "fp/modules/access-token/Service";
import { CreateDTO, FMPayAccessTokenModel, PaginationQuery } from "fp/modules/access-token/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const accessTokenFindById = createAsyncThunk<FMPayAccessTokenModel, string>(
  "access.token/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const accessTokenDeleteByIds = createAsyncThunk<boolean, string[]>(
  "access.token/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const accessTokenPagination = createAsyncThunk<
  FMPayPagination<FMPayAccessTokenModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>("access.token/pagination", async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
  const resp = await Service.pagination(query);
  return resp.data;
});

export const accessTokenUpdate = createAsyncThunk<FMPayAccessTokenModel, FMPayAccessTokenModel>(
  "access.token/update",
  async (model: FMPayAccessTokenModel) => {
    const resp = await Service.update(model);
    return resp.data;
  }
);

export const accessTokenCreate = createAsyncThunk<FMPayAccessTokenModel, CreateDTO>(
  "access.token/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface AccessTokenState {
  model: FMPayAccessTokenModel | null;
  models: FMPayPagination<FMPayAccessTokenModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: AccessTokenState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    update: "",
    create: false
  }
};

export const accessTokenSlice = createSlice({
  name: "accessToken",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(accessTokenFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(accessTokenFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(accessTokenFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(accessTokenDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(accessTokenDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(accessTokenDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(accessTokenUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(accessTokenUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(accessTokenUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(accessTokenPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(accessTokenPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(accessTokenPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(accessTokenCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(accessTokenCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(accessTokenCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default accessTokenSlice.reducer;
