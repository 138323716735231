import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Service } from "fp/modules/buybtc-account/Service";
import {
  CreateDTO,
  FMPayBuyBtcAccountModel,
  PaginationQuery
} from "fp/modules/buybtc-account/type";
import { FMPayPagination, FMPayPaginationQuery } from "fp/store/type";

export const buybtcAccountFindById = createAsyncThunk<FMPayBuyBtcAccountModel, string>(
  "buybtc.account/find.by.id",
  async (id: string) => {
    const resp = await Service.findById(id);
    return resp.data;
  }
);

export const buybtcAccountDeleteByIds = createAsyncThunk<boolean, string[]>(
  "buybtc.account/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const buybtcAccountPagination = createAsyncThunk<
  FMPayPagination<FMPayBuyBtcAccountModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>("buybtc.account/pagination", async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
  const resp = await Service.pagination(query);
  return resp.data;
});

export const buybtcAccountUpdate = createAsyncThunk<
  FMPayBuyBtcAccountModel,
  FMPayBuyBtcAccountModel
>("buybtc.account/update", async (model: FMPayBuyBtcAccountModel) => {
  const resp = await Service.update(model);
  return resp.data;
});

export const buybtcAccountCreate = createAsyncThunk<FMPayBuyBtcAccountModel, CreateDTO>(
  "buybtc.account/create",
  async (dto: CreateDTO) => {
    const resp = await Service.create(dto);
    return resp.data;
  }
);

export interface BuyBtcAccountState {
  model: FMPayBuyBtcAccountModel | null;
  models: FMPayPagination<FMPayBuyBtcAccountModel>;
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    update: string;
    create: boolean;
  };
}

const InitialState: BuyBtcAccountState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    update: "",
    create: false
  }
};

export const buybtcAccountSlice = createSlice({
  name: "buybtcAccount",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(buybtcAccountFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(buybtcAccountFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(buybtcAccountFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(buybtcAccountDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(buybtcAccountDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(buybtcAccountDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(buybtcAccountUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(buybtcAccountUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(buybtcAccountUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(buybtcAccountPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(buybtcAccountPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(buybtcAccountPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(buybtcAccountCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(buybtcAccountCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(buybtcAccountCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default buybtcAccountSlice.reducer;
