import { FMPayPaginationQuery, FMPayResponse, FMPayResponsePagination } from "fp/store/type";
import { CreateDTO, FMPayAccessTokenModel, PaginationQuery } from "fp/modules/access-token/type";
import { HttpClient } from "fp/store/HttpClient";

export class Service {
  public static async findById(id: string): Promise<FMPayResponse<FMPayAccessTokenModel>> {
    return await HttpClient.Get(`/v1/access/token/${id}`);
  }

  public static async deleteByIds(ids: string[]): Promise<FMPayResponse<boolean>> {
    return await HttpClient.Post(`/v1/access/token/deleted`, { ids });
  }

  public static async update(
    model: FMPayAccessTokenModel
  ): Promise<FMPayResponse<FMPayAccessTokenModel>> {
    return await HttpClient.Post(`/v1/access/token/update`, model);
  }

  public static async create(dto: CreateDTO): Promise<FMPayResponse<FMPayAccessTokenModel>> {
    return await HttpClient.Post(`/v1/access/token/add`, dto);
  }

  public static async pagination(
    query: FMPayPaginationQuery<Partial<PaginationQuery>>
  ): Promise<FMPayResponsePagination<FMPayAccessTokenModel>> {
    return await HttpClient.Post(`/v1/access/token/pagination`, query);
  }
}
