import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Service } from "fp/modules/wechat-group-account-book/Service";
import {
  CreateDTO,
  FMPayWechatGroupAccountBookModel,
  PaginationQuery
} from "fp/modules/wechat-group-account-book/type";
import { FMPayPagination, FMPayPaginationQuery, OrderStatus } from "fp/store/type";
import { FMPayOrderModel } from "fp/modules/order/type";
import { Service as OrderService } from "fp/modules/order/Service";
import { FMPayWorkSchedulingOperationModel } from "fp/modules/work-scheduling-operation/type";
import { Service as WorkSchedulingOperationService } from "fp/modules/work-scheduling-operation/Service";

export const wechatGroupAccountBookFindById = createAsyncThunk<
  FMPayWechatGroupAccountBookModel,
  string
>("wechat.group.account.book/find.by.id", async (id: string) => {
  const resp = await Service.findById(id);
  return resp.data;
});

export const wechatGroupAccountBookDeleteByIds = createAsyncThunk<boolean, string[]>(
  "wechat.group.account.book/delete.by.ids",
  async (ids: string[]) => {
    const resp = await Service.deleteByIds(ids);
    return resp.data;
  }
);

export const wechatGroupAccountBookPagination = createAsyncThunk<
  FMPayPagination<FMPayWechatGroupAccountBookModel>,
  FMPayPaginationQuery<Partial<PaginationQuery>>
>(
  "wechat.group.account.book/pagination",
  async (query: FMPayPaginationQuery<Partial<PaginationQuery>>) => {
    const resp = await Service.pagination(query);
    return resp.data;
  }
);

export const wechatGroupAccountBookUpdate = createAsyncThunk<
  FMPayWechatGroupAccountBookModel,
  FMPayWechatGroupAccountBookModel
>("wechat.group.account.book/update", async (model: FMPayWechatGroupAccountBookModel) => {
  const resp = await Service.update(model);
  return resp.data;
});

export const wechatGroupAccountBookCreate = createAsyncThunk<
  FMPayWechatGroupAccountBookModel,
  CreateDTO
>("wechat.group.account.book/create", async (dto: CreateDTO) => {
  const resp = await Service.create(dto);
  return resp.data;
});

export const wechatGroupAccountBookQueryWorkSchedulingOperation = createAsyncThunk<
  FMPayWorkSchedulingOperationModel,
  string
>("wechat.group.account.book.query.work.scheduling.operation", async (id) => {
  const resp = await WorkSchedulingOperationService.findById(id);
  return resp.data;
});

export const wechatGroupAccountBookDiffInitial = createAsyncThunk<
  {
    orders: FMPayOrderModel[];
    wechat: FMPayWechatGroupAccountBookModel[];
  },
  {
    workSchedulingOperationId: string;
    groupId: string;
    since: string;
    until: string;
  }
>("wechat.group.account.book.diff.initial", async (args) => {
  const orders = await OrderService.pagination({
    current: 1,
    size: 9999,
    query: {
      orderByCreateTime: "asc",
      groupId: args.groupId,
      sameWorkSchedulingOperationId: args.workSchedulingOperationId,
      status: [OrderStatus.PAID]
    }
  });
  const wechat = await Service.pagination({
    current: 1,
    size: 9999,
    query: {
      groupId: args.groupId,
      messageTimeSince: args.since,
      messageTimeUntil: args.until
    }
  });
  return {
    orders: orders.data.data,
    wechat: wechat.data.data
  };
});

export interface WechatGroupAccountBookState {
  model: FMPayWechatGroupAccountBookModel | null;
  models: FMPayPagination<FMPayWechatGroupAccountBookModel>;
  wechat: FMPayWechatGroupAccountBookModel[];
  orders: FMPayOrderModel[];
  workSchedulingOperation: FMPayWorkSchedulingOperationModel | null;
  loading: {
    findById: boolean;
    deleted: string[];
    pagination: boolean;
    update: string;
    create: boolean;
    findWorkSchedulingOperation: boolean;
    queryOrders: boolean;
  };
}

const InitialState: WechatGroupAccountBookState = {
  model: null,
  models: {
    total: 0,
    size: 10,
    current: 1,
    pages: 0,
    latest: false,
    data: []
  },
  wechat: [],
  orders: [],
  workSchedulingOperation: null,
  loading: {
    findById: false,
    deleted: [],
    pagination: false,
    update: "",
    create: false,
    findWorkSchedulingOperation: false,
    queryOrders: false
  }
};

export const wechatGroupAccountBookSlice = createSlice({
  name: "wechatGroupAccountBook",
  initialState: { ...InitialState },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(wechatGroupAccountBookFindById.pending, (state) => {
      state.loading.findById = true;
    });
    builder.addCase(wechatGroupAccountBookFindById.rejected, (state) => {
      state.loading.findById = false;
    });
    builder.addCase(wechatGroupAccountBookFindById.fulfilled, (state, action) => {
      state.loading.findById = false;
      state.model = action.payload;
    });

    builder.addCase(wechatGroupAccountBookDeleteByIds.pending, (state, action) => {
      state.loading.deleted = action.meta.arg;
    });
    builder.addCase(wechatGroupAccountBookDeleteByIds.rejected, (state) => {
      state.loading.deleted = [];
    });
    builder.addCase(wechatGroupAccountBookDeleteByIds.fulfilled, (state) => {
      state.loading.deleted = [];
    });

    builder.addCase(wechatGroupAccountBookUpdate.pending, (state, action) => {
      state.loading.update = action.meta.arg.id;
    });
    builder.addCase(wechatGroupAccountBookUpdate.rejected, (state) => {
      state.loading.update = "";
    });
    builder.addCase(wechatGroupAccountBookUpdate.fulfilled, (state, action) => {
      state.loading.update = "";
      state.model = action.payload;
    });

    builder.addCase(wechatGroupAccountBookPagination.pending, (state) => {
      state.loading.pagination = true;
    });
    builder.addCase(wechatGroupAccountBookPagination.rejected, (state) => {
      state.loading.pagination = false;
    });
    builder.addCase(wechatGroupAccountBookPagination.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.models = action.payload;
    });

    builder.addCase(wechatGroupAccountBookQueryWorkSchedulingOperation.pending, (state) => {
      state.loading.findWorkSchedulingOperation = true;
    });
    builder.addCase(wechatGroupAccountBookQueryWorkSchedulingOperation.rejected, (state) => {
      state.loading.findWorkSchedulingOperation = false;
    });
    builder.addCase(
      wechatGroupAccountBookQueryWorkSchedulingOperation.fulfilled,
      (state, action) => {
        state.loading.findWorkSchedulingOperation = false;
        state.workSchedulingOperation = action.payload;
      }
    );

    builder.addCase(wechatGroupAccountBookDiffInitial.pending, (state) => {
      state.loading.pagination = true;
      state.loading.queryOrders = true;
    });
    builder.addCase(wechatGroupAccountBookDiffInitial.rejected, (state) => {
      state.loading.pagination = false;
      state.loading.queryOrders = false;
    });
    builder.addCase(wechatGroupAccountBookDiffInitial.fulfilled, (state, action) => {
      state.loading.pagination = false;
      state.loading.queryOrders = false;

      state.wechat = action.payload.wechat;
      state.orders = action.payload.orders;
    });

    builder.addCase(wechatGroupAccountBookCreate.pending, (state) => {
      state.loading.create = true;
    });
    builder.addCase(wechatGroupAccountBookCreate.rejected, (state) => {
      state.loading.create = false;
    });
    builder.addCase(wechatGroupAccountBookCreate.fulfilled, (state, action) => {
      state.loading.create = false;
      state.model = action.payload;
    });
  }
});

export default wechatGroupAccountBookSlice.reducer;
